import { defaultAnimateLayoutChanges, useSortable } from "@dnd-kit/sortable";
import { Box, ClickAwayListener, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, useEffect, useRef, useState } from "react";
import { CSS } from "@dnd-kit/utilities";
import {
  LinkModel,
  LinkType,
  useEmbeddedLazyQuery,
  useUpdateLinkModifierMutation,
  useUpdateLinkMutation,
} from "../../../../../generated/graphql";
import { localLinkUpdate } from "../state";
import FunctionsIconPanel, { Functions } from "./functions/FunctionsIconPanel";
import LinkWrapper from "./LinkWrapper";
import { Icon } from "../../../../../ui";
import { getDefaultPlaceholder, isEmbedable } from "./EmbedsMarket";

interface Props
  extends Omit<LinkModel, "children" | "createdAt" | "updatedAt"> {
  isOverlay: boolean;
  isChildren: boolean;
}

export function animateLayoutChanges(args: any) {
  const { isSorting, wasSorting, newIndex } = args;

  if (isSorting || wasSorting) {
    return defaultAnimateLayoutChanges(args);
  }

  return false;
}

const TITLE_LABELS: any = {
  [LinkType.Button]: "Title",
  [LinkType.DropdownButton]: "Dropdown title",
  [LinkType.Grid]: "Image grid title",
  [LinkType.Embeded]: "Title",
  [LinkType.SocialIcon]: "Title",
};

const BUTTONS_WITHOUT_URL = [LinkType.DropdownButton, LinkType.Grid];

const BioLink: FC<Props> = (p) => {
  const {
    id,
    isActive,
    title,
    url,
    isOverlay,
    isChildren = false,
    type,
    clicks,
    leapTime,
    scheduleStart,
    thumbnail,
    priority,
    embeded,
    isNew,
    urlHolder,
  } = p;
  const oldState = useRef({
    title,
    url,
  });

  const [serverUpdate] = useUpdateLinkModifierMutation();

  const [embededQuery] = useEmbeddedLazyQuery({
    onCompleted: (d) => {
      if (d.embeded)
        localLinkUpdate({
          id,
          data: {
            embededIframe: d.embeded,
            embeded: true,
            type: LinkType.Embeded,
          },
        });
      serverUpdate({
        variables: {
          data: {
            id,
            embeded: true,
            type: LinkType.Embeded,
          },
        },
      });
    },
  });

  const usedFunctions = {
    leap: leapTime > 0,
    schedule: scheduleStart !== null,
    thumbnail: thumbnail !== null,
    priority: priority > 0,
    dropdown: type === LinkType.DropdownButton,
    grid: type === LinkType.Grid,
    embeded,
  };

  const getDefaultOpenedFunction = () => {
    if (isNew) {
      switch (type) {
        case LinkType.DropdownButton:
          return Functions[4];
        case LinkType.Grid:
          return Functions[5];
        default:
          return null;
      }
    }

    return null;
  };

  const {
    attributes,
    listeners,
    setNodeRef,
    transform,
    transition,
    isDragging,
    isSorting,
    active,
  } = useSortable({
    id: isOverlay ? "" : id,
    animateLayoutChanges: animateLayoutChanges,
  });

  const style = {
    transform: CSS.Translate.toString(transform),
    transition: transition,
  };

  const [update, {error}] = useUpdateLinkMutation();

  const [_title, setTitle] = useState(title);
  const [_url, setUrl] = useState(
    type === LinkType.DropdownButton || type === LinkType.Grid ? type : url
  );
  const [_isActive, setIsActive] = useState(isActive);

  const [openedFunction, setOpenedFunction] = useState<any>(
    getDefaultOpenedFunction()
  );

  const renderOpenedFunction = (f: any) => {
    const Function = f.component;
    return <Function {...p} />;
  };

  const onChangeUrl = (e: any) => {
    if (type === LinkType.DropdownButton || type === LinkType.Grid) return;

    if (type === LinkType.Embeded) {
      const embedable = isEmbedable(e.target.value);

      if (embedable) {
        embededQuery({
          variables: {
            url: e.target.value,
          },
        });
      } else {
        serverUpdate({
          variables: {
            data: {
              id,
              embeded: false,
              type: LinkType.Button,
            },
          },
        });
      }
    }

    setUrl(e.target.value);
  };

  const onChangeTitle = (e: any) => {
    if (e.target.value.length > 60) return;
    setTitle(e.target.value);
    localLinkUpdate({
      id,
      data: {
        title: e.target.value,
      },
    });
  };

  const onBlurInput = async () => {
    try {
      if (oldState.current.title !== _title || oldState.current.url !== _url) {
        await update({
          variables: {
            data: {
              id,
              title: _title,
              url: _url,
              isActive: _isActive,
            },
          },
        });

        oldState.current.title = _title;
        oldState.current.url = _url;
      }
    } catch (error) {}
  };

  useEffect(() => {
    if (isActive !== _isActive)
      update({
        variables: {
          data: {
            id,
            title: _title,
            url: _url,
            isActive: _isActive,
          },
        },
      });
  }, [_isActive]);

  const onChangeActiveState = () => {
    setIsActive(!_isActive);
  };

  const isValidTitle = () => _title.length > 0;
  const isValidUrl = () => {
    if (type === LinkType.Button) {
      return _url.length > 0;
    }

    return true;
  };

  const urlInputError = () => {
    if(!isValidUrl()) return "Not valid URL";
    if(error) return error.message;

    return "";
  }

  return (
    <ClickAwayListener onClickAway={() => setOpenedFunction(null)}>
      <Stack
        id={"li-" + id}
        ref={isOverlay ? null : setNodeRef}
        sx={{
          backgroundColor: "#fff",
          borderRadius: "25px",
          ...style,
          ...(isChildren && {
            border: " 1px solid rgba(15, 117, 251, 0.2) ",
          }),
        }}
      >
        <LinkWrapper
          attributes={attributes}
          listeners={isOverlay ? null : listeners}
          isOpened={!!openedFunction}
          isActive={_isActive}
          onChangeActiveState={onChangeActiveState}
        >
          <Stack rowGap={0}>
            {LinkType.Button === type && (
              <Typography
                component={"span"}
                textAlign={"right"}
                sx={{
                  fontSize: 14,
                  display: "inline-flex",
                  alignSelf: "flex-end",
                }}
              >
                {clicks} Clicks <Icon type="Clicks" width={"20px"} ml={1} />{" "}
              </Typography>
            )}
            <TextField
              value={_title}
              label={TITLE_LABELS[type]}
              onChange={onChangeTitle}
              onBlur={onBlurInput}
              color={isValidTitle() ? "primary" : "error"}
              helperText={isValidTitle() ? "" : "Title is required"}
              sx={(t: any) => ({
                ...t.components?.MuiTextField?.defaultProps?.sx,
                "& .MuiFormHelperText-root": {
                  color: "error.main",
                  mt: 1,
                },
              })}
            />
            {!BUTTONS_WITHOUT_URL.includes(type) && (
              <TextField
                value={encodeURI(_url.trimStart().trimEnd())}
                margin="normal"
                onBlur={onBlurInput}
                label={
                  getDefaultPlaceholder(urlHolder || "") ||
                  "Target URL (www.example.com)"
                }
                onChange={onChangeUrl}
                color={isValidUrl() ? "primary" : "error"}
                helperText={urlInputError()}
                InputProps={{
                  endAdornment: urlHolder ? (
                    <Box
                      component="img"
                      src={`/icons/embeds/${urlHolder}.svg`}
                      sx={{ width: "15px", height: "15px" }}
                    />
                  ) : null,
                  disableUnderline: true,
                }}
                sx={(t: any) => ({
                  ...t.components?.MuiTextField?.defaultProps?.sx,
                  "& .MuiFormHelperText-root": {
                    color: "error.main",
                    mt: 1,
                  },
                })}
              />
            )}

            <FunctionsIconPanel
              type={type}
              usedFunctions={usedFunctions}
              omitFunctions={isChildren ? ["dropdown", "grid"] : []}
              openedFunction={openedFunction}
              openFunction={setOpenedFunction}
            />
          </Stack>
        </LinkWrapper>
        {openedFunction && renderOpenedFunction(openedFunction)}
      </Stack>
    </ClickAwayListener>
  );
};

export default React.memo(BioLink, (prev, next) => {
  return JSON.stringify(prev) === JSON.stringify(next);
});
